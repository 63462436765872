.InGameNavigation_navigation-container__f_AvP {
  background-color: var(--color_lvl_5);
  display: grid;
  grid-template-columns: var(--header-grid);
  height: 90px;
  border-bottom: 4px solid var(--color_lvl_3);
  position: relative;

  @media (max-width: 1024px) {
    align-items: flex-end;
    border-bottom: none;
    display: flex;
    height: auto;
    justify-content: space-between;
    overflow-x: auto;
    padding-top: 20px;
    -webkit-overflow-scrolling: touch;
    scrollbar-width: none;
    -ms-overflow-style: none;
    &::-webkit-scrollbar {
      display: none;
    }
  }
}

.InGameNavigation_navigation-inner-container__BVjvt {
  display: flex;
  grid-column: content-start / content-end;
  width: 100%;
  position: relative;
  @media (max-width: 1024px) {
    display: column;
  }
}

.InGameNavigation_game-logo-container__54feb {
  height: 100%;
  display: grid;
  place-content: center;
  margin-right: 6%;
  @media (max-width: 1024px) {
    display: none;
  }
}

svg.InGameNavigation_game-logo__OVbHn {
  width: -moz-fit-content;
  width: fit-content;
  height: 50px;
}

.InGameNavigation_divider__87mJ9 {
  background-color: var(--color_lvl_3);
  bottom: 0;
  height: 4px;
  position: absolute;
  width: 100%;
  z-index: 0;
  @media (min-width: 1024px) {
    display: none;
  }
}

.InGameNavigation_links-container__ZPafc {
  display: flex;
  align-items: center;
  column-gap: clamp(1rem, calc(1rem + ((1vw - 0.625rem) * 3.4722)), 5rem);
  position: relative;
  @media (max-width: 1024px) {
    z-index: 2;
    column-gap: 2rem;
    margin: 0 auto;
  }
}

.InGameNavigation_game-link__bJA8o {
  --nav-content-color: var(--color_lvl_0);
  --nav-border-color: var(--color_lvl_3);
  --nav-selection-color: var(--color_bcg_accent_1); /* TODO: add women's color */
  text-decoration: none;
  padding: 0 0.5rem;
  color: var(--nav-content-color);
  text-transform: uppercase;
  font-size: 1.2em;
  font-weight: normal;
  font-style: oblique;
  line-height: 1;
  min-width: max-content;
  z-index: 1;
  cursor: pointer;
  position: relative;
  height: 100%;
  display: grid;
  place-content: center;

  &:visited {
    color: var(--nav-content-color);
  }

  &:first-child {
    margin-left: 10px;
  }

  @media (max-width: 1024px) {
    border-bottom: 4px solid var(--nav-border-color);
    padding-bottom: 16px;
  }

  &:hover,
  &:focus {
    color: var(--nav-selection-color);
  }

  &.InGameNavigation_active-page__sgvAY {
    --nav-content-color: var(--color_lvl_-5);
    --nav-border-color: var(--color_lvl_-5);
  }
  &.InGameNavigation_active-page__sgvAY::after {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    bottom: -4px; /* Adjust based on your row border width */
    height: 4px;
    background-color: var(--nav-border-color);
  }
}

.InGameNavigation_account-settings__sMl9K {
  width: 28px;
  height: 28px;
  /* transform: translateY(1rem); */
  border-radius: 50px;
  transition: background-color 0.3s;
  cursor: pointer;

  &:hover,
  &:focus {
    background-color: var(--color_lvl_3);
  }

  @media (max-width: 1024px) {
    display: none;
  }
}

.DesktopGameNaviation_navigation-container__3x5jq {
  background-color: var(--color_lvl_4);
  border-bottom: 1px solid var(--color_lvl_3);
  height: 57px;
  width: 100%;

  @media (max-width: 1024px) {
    display: none !important;
  }
}

.DesktopGameNaviation_navigation__COE04 {
  display: flex;
  height: 100%;
  list-style-type: none;
  margin: 0 auto;
  padding: 0;
  width: 100%;
}

.DesktopGameNaviation_nav-item__Nfemm {
  --inner-link-color: var(--color_lvl_1);
  --selection-color: var(--color_bcg_accent1);
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  text-decoration: none;
  transform: translateY(1px);
  cursor: pointer;

  &.DesktopGameNaviation_game-link__ttndX {
    padding: 0 17px;
  }

  &:hover,
  &:focus-visible {
    --inner-link-color: var(--selection-color);
  }

  &.DesktopGameNaviation_active__RMA9f {
    background: var(--color_lvl_5);
    color: var(--color_lvl_-5);
  }

  &:first-child {
    max-width: 117px;
    justify-content: flex-start;
  }

  &.DesktopGameNaviation_womens__Suu7M {
    --selection-color: var(--color_bcg_accent_2);
  }
}

.DesktopGameNaviation_play-logo__wgTLX {
  width: 75px;
  height: 27px;
  margin-right: 42px;
}

.DesktopGameNaviation_inner-link__fIFjy {
  color: var(--inner-link-color);
  font-style: oblique;
  font-weight: normal;
  font-size: 1.2em;
  line-height: normal;
  text-transform: uppercase;
  transition: color 0.2s ease-in-out;
}

.MobileGameNavigation_container__Obwdj {
  display: none;
  height: 7.5em;
  background-color: var(--color_lvl_5);
  border-bottom: 0.1em solid var(--color_lvl_3);

  @media (max-width: 1024px) {
    display: block;
  }
  &.MobileGameNavigation_is-native__vMn4y {
    display: block;
  }
}

.MobileGameNavigation_menu-root__bWl_a {
  height: 100%;
}

.MobileGameNavigation_grid-contents__ksAKv {
  display: grid;
  grid-template-columns: 28px auto 28px;
  align-items: center;
  height: 100%;
  padding: 0em 1.6em;
}

.MobileGameNavigation_account-settings__zNvr8 {
  border-radius: 50px;
  transition: background-color 0.3s;

  &:hover,
  &:focus {
    background-color: var(--color_lvl_3);
  }
}

.MobileGameNavigation_account-settings-svg__HMQ7H {
  height: 28px;
  width: 28px;
}

.MobileGameNavigation_game-trigger__qwA_E {
  display: flex;
  align-items: center;
  justify-content: center;
}

.MobileGameNavigation_game-trigger-svg__DSQoQ {
  margin: 0 25px;
  height: 35px;
}

.MobileGameNavigation_caret-trigger__126OJ {
  transition: transform 300ms;
  color: var(--color_lvl_-5);

  &[data-state="open"] {
    transform: rotate(-180deg);
  }
  &[data-state="closed"] {
    transform: rotate(0deg);
  }
}

.MobileGameNavigation_caret-svg__uFzuH {
  height: 28px;
  width: 28px;
}

@keyframes MobileGameNavigation_menu-open__835gn {
  from {
    height: 0;
  }
  to {
    height: var(--radix-collapsible-content-height);
  }
}

@keyframes MobileGameNavigation_menu-close__iveE8 {
  from {
    height: var(--radix-collapsible-content-height);
  }
  to {
    height: 0;
  }
}

.MobileGameNavigation_collapsible__XF4eR {
  position: absolute;
  z-index: 10;
  width: 100%;
  top: 8.5em;
  overflow: hidden;
  background-color: var(--color_lvl_5);

  &[data-state="open"] {
    animation: MobileGameNavigation_menu-open__835gn 200ms ease-out;
  }
  &[data-state="closed"] {
    animation: MobileGameNavigation_menu-close__iveE8 200ms ease-out;
  }
}

.MobileGameNavigation_game-row__YI_dz {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  border-top: 0.5px solid var(--color_lvl_3);
  padding: 1.6em 0em;
  transition: background-color 0.2s ease-in-out;

  &:first-child {
    border-top: none;
  }
  &:hover {
    background-color: var(--color_lvl_3);
  }
}

.MobileGameNavigation_return-to-play-home__mhzoG {
  text-transform: uppercase;
  text-decoration: none;
  font-size: 1em;
  font-weight: 500;
  font-style: normal;
  letter-spacing: 1px;
  width: 100%;
  text-align: center;
  cursor: pointer;
}

@keyframes MobileGameNavigation_background-fade-in__zwH2o {
  from {
    opacity: 0;
  }
  to {
    opacity: 0.5;
  }
}

@keyframes MobileGameNavigation_background-fade-out__rtKXv {
  from {
    opacity: 0.5;
  }
  to {
    opacity: 0;
  }
}

.MobileGameNavigation_background-trigger__Gp0Sf {
  z-index: 1;
  background-color: #000;
  opacity: 0.5;
  position: absolute;
  top: 8.5em;
  bottom: 0;
  height: calc(100vh - 8.5em);
  width: 100%;

  &.MobileGameNavigation_hide__288H1 {
    display: none;
  }

  &.MobileGameNavigation_show__GicqO {
    display: block;
  }

  &[data-state="open"] {
    animation: MobileGameNavigation_background-fade-in__zwH2o 200ms ease-out;
  }
  &[data-state="closed"] {
    animation: MobileGameNavigation_background-fade-out__rtKXv 200ms ease-out;
  }
}

.MobileGameNavigation_account-settings__zNvr8 {
  border-radius: 50px;
  transition: background-color 0.2s ease-in-out;

  &:hover,
  &:focus {
    background-color: var(--color_lvl_3);
  }
}

.MobileGameNavigation_account-settings-svg__HMQ7H {
  height: 28px;
  width: 28px;
}

.MobileGameNavigation_game-image-wrapper__0nAdi {
  width: 100%;
  display: flex;
  justify-content: center;
}

.MobileGameNavigation_game-image-svg__ElgIO {
  height: 3.5em;
}

.UtilityBar_container__yXoav {
  --header-max: calc(100dvw - 6rem) !important;
  --content: var(--header-max);
  background: rgba(0, 58, 93, 1);
  color: #fff;
  font-size: 13px;
  height: 36px;
  padding: 0 10px;
  width: 100%;
  @media (max-width: 1024px) {
    display: none !important;
  }
}

.UtilityBar_utility-bar-link__mHZKw {
  color: #fff;
  text-decoration: none;
  &:hover,
  &:focus {
    text-decoration: underline;
  }
}

.UtilityBar_content__I3_qq {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.UtilityBar_ncaa-content-wrapper__ARMdr {
  align-items: center;
  display: flex;
  height: 36px;
  column-gap: 1.2rem;
}

.UtilityBar_presented-by-container__1g_7O {
  display: flex;
  align-items: center;
}

.UtilityBar_logo__9oczz {
  margin-right: 8px;
  transform: translateY(1px);
}

.UtilityBar_presented-by-text__SeQLS {
  font-family: "Helvetica";
  font-weight: small;
  font-size: 8px;
  letter-spacing: 0.1px;

  &:first-child {
    transform: translateY(3px);
    margin-right: 16px;
  }
}

:root {
  --header-max: 1440px;
  --space_m: 1rem;
  --gutter-max: calc(1rem + 10vw);
  --gap: var(--space_m);
  --content: min(var(--header-max), 100% - var(--gap) * 2);
  --gutter: 1fr;
  --popout: minmax(0px, 1rem);
  --header-grid: [full-start] 0px [gutter-start] var(--gutter) [popout-start] var(--popout) [content-start]
    var(--content) [content-end] var(--popout) [popout-end] var(--gutter) [gutter-end] 0px [full-end];
}

.header-grid {
  display: grid;
  grid-template-columns: var(--header-grid);
  width: 100%;
}

:where(.header-grid) > * {
  grid-column: content-start/content-end;
}

@font-face {
font-family: '__ringside_959bb9';
src: url(/_next/static/media/a2bacf4681c15194-s.p.woff2) format('woff2');
font-display: swap;
font-weight: normal;
font-style: normal;
}

@font-face {
font-family: '__ringside_959bb9';
src: url(/_next/static/media/e10a9fc213a4c6e8-s.p.woff2) format('woff2');
font-display: swap;
font-weight: normal;
font-style: oblique;
}

@font-face {
font-family: '__ringside_959bb9';
src: url(/_next/static/media/35a0ed54ac9b7969-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 500;
font-style: normal;
}

@font-face {
font-family: '__ringside_959bb9';
src: url(/_next/static/media/abb28ade56b85d3e-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 500;
font-style: oblique;
}

@font-face {
font-family: '__ringside_959bb9';
src: url(/_next/static/media/0c48bb7ddbc1f2b1-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 600;
font-style: normal;
}

@font-face {
font-family: '__ringside_959bb9';
src: url(/_next/static/media/437eacecab5add86-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 700;
font-style: normal;
}

@font-face {
font-family: '__ringside_959bb9';
src: url(/_next/static/media/239862979bee6638-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 900;
font-style: normal;
}@font-face {font-family: '__ringside_Fallback_959bb9';src: local("Arial");ascent-override: 94.20%;descent-override: 19.63%;line-gap-override: 3.93%;size-adjust: 101.91%
}.__className_959bb9 {font-family: '__ringside_959bb9', '__ringside_Fallback_959bb9'
}

