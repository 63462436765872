.navigation-container {
  background-color: var(--color_lvl_4);
  border-bottom: 1px solid var(--color_lvl_3);
  height: 57px;
  width: 100%;

  @media (max-width: 1024px) {
    display: none !important;
  }
}

.navigation {
  display: flex;
  height: 100%;
  list-style-type: none;
  margin: 0 auto;
  padding: 0;
  width: 100%;
}

.nav-item {
  --inner-link-color: var(--color_lvl_1);
  --selection-color: var(--color_bcg_accent1);
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  text-decoration: none;
  transform: translateY(1px);
  cursor: pointer;

  &.game-link {
    padding: 0 17px;
  }

  &:hover,
  &:focus-visible {
    --inner-link-color: var(--selection-color);
  }

  &.active {
    background: var(--color_lvl_5);
    color: var(--color_lvl_-5);
  }

  &:first-child {
    max-width: 117px;
    justify-content: flex-start;
  }

  &.womens {
    --selection-color: var(--color_bcg_accent_2);
  }
}

.play-logo {
  width: 75px;
  height: 27px;
  margin-right: 42px;
}

.inner-link {
  color: var(--inner-link-color);
  font-style: oblique;
  font-weight: normal;
  font-size: 1.2em;
  line-height: normal;
  text-transform: uppercase;
  transition: color 0.2s ease-in-out;
}
