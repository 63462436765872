.container {
  display: none;
  height: 7.5em;
  background-color: var(--color_lvl_5);
  border-bottom: 0.1em solid var(--color_lvl_3);

  @media (max-width: 1024px) {
    display: block;
  }
  &.is-native {
    display: block;
  }
}

.menu-root {
  height: 100%;
}

.grid-contents {
  display: grid;
  grid-template-columns: 28px auto 28px;
  align-items: center;
  height: 100%;
  padding: 0em 1.6em;
}

.account-settings {
  border-radius: 50px;
  transition: background-color 0.3s;

  &:hover,
  &:focus {
    background-color: var(--color_lvl_3);
  }
}

.account-settings-svg {
  height: 28px;
  width: 28px;
}

.game-trigger {
  display: flex;
  align-items: center;
  justify-content: center;
}

.game-trigger-svg {
  margin: 0 25px;
  height: 35px;
}

.caret-trigger {
  transition: transform 300ms;
  color: var(--color_lvl_-5);

  &[data-state="open"] {
    transform: rotate(-180deg);
  }
  &[data-state="closed"] {
    transform: rotate(0deg);
  }
}

.caret-svg {
  height: 28px;
  width: 28px;
}

@keyframes menu-open {
  from {
    height: 0;
  }
  to {
    height: var(--radix-collapsible-content-height);
  }
}

@keyframes menu-close {
  from {
    height: var(--radix-collapsible-content-height);
  }
  to {
    height: 0;
  }
}

.collapsible {
  position: absolute;
  z-index: 10;
  width: 100%;
  top: 8.5em;
  overflow: hidden;
  background-color: var(--color_lvl_5);

  &[data-state="open"] {
    animation: menu-open 200ms ease-out;
  }
  &[data-state="closed"] {
    animation: menu-close 200ms ease-out;
  }
}

.game-row {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  border-top: 0.5px solid var(--color_lvl_3);
  padding: 1.6em 0em;
  transition: background-color 0.2s ease-in-out;

  &:first-child {
    border-top: none;
  }
  &:hover {
    background-color: var(--color_lvl_3);
  }
}

.return-to-play-home {
  text-transform: uppercase;
  text-decoration: none;
  font-size: 1em;
  font-weight: 500;
  font-style: normal;
  letter-spacing: 1px;
  width: 100%;
  text-align: center;
  cursor: pointer;
}

@keyframes background-fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 0.5;
  }
}

@keyframes background-fade-out {
  from {
    opacity: 0.5;
  }
  to {
    opacity: 0;
  }
}

.background-trigger {
  z-index: 1;
  background-color: #000;
  opacity: 0.5;
  position: absolute;
  top: 8.5em;
  bottom: 0;
  height: calc(100vh - 8.5em);
  width: 100%;

  &.hide {
    display: none;
  }

  &.show {
    display: block;
  }

  &[data-state="open"] {
    animation: background-fade-in 200ms ease-out;
  }
  &[data-state="closed"] {
    animation: background-fade-out 200ms ease-out;
  }
}

.account-settings {
  border-radius: 50px;
  transition: background-color 0.2s ease-in-out;

  &:hover,
  &:focus {
    background-color: var(--color_lvl_3);
  }
}

.account-settings-svg {
  height: 28px;
  width: 28px;
}

.game-image-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
}

.game-image-svg {
  height: 3.5em;
}
