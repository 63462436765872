:root {
  --header-max: 1440px;
  --space_m: 1rem;
  --gutter-max: calc(1rem + 10vw);
  --gap: var(--space_m);
  --content: min(var(--header-max), 100% - var(--gap) * 2);
  --gutter: 1fr;
  --popout: minmax(0px, 1rem);
  --header-grid: [full-start] 0px [gutter-start] var(--gutter) [popout-start] var(--popout) [content-start]
    var(--content) [content-end] var(--popout) [popout-end] var(--gutter) [gutter-end] 0px [full-end];
}

.header-grid {
  display: grid;
  grid-template-columns: var(--header-grid);
  width: 100%;
}

:where(.header-grid) > * {
  grid-column: content-start/content-end;
}
