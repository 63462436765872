.navigation-container {
  background-color: var(--color_lvl_5);
  display: grid;
  grid-template-columns: var(--header-grid);
  height: 90px;
  border-bottom: 4px solid var(--color_lvl_3);
  position: relative;

  @media (max-width: 1024px) {
    align-items: flex-end;
    border-bottom: none;
    display: flex;
    height: auto;
    justify-content: space-between;
    overflow-x: auto;
    padding-top: 20px;
    -webkit-overflow-scrolling: touch;
    scrollbar-width: none;
    -ms-overflow-style: none;
    &::-webkit-scrollbar {
      display: none;
    }
  }
}

.navigation-inner-container {
  display: flex;
  grid-column: content-start / content-end;
  width: 100%;
  position: relative;
  @media (max-width: 1024px) {
    display: column;
  }
}

.game-logo-container {
  height: 100%;
  display: grid;
  place-content: center;
  margin-right: 6%;
  @media (max-width: 1024px) {
    display: none;
  }
}

svg.game-logo {
  width: fit-content;
  height: 50px;
}

.divider {
  background-color: var(--color_lvl_3);
  bottom: 0;
  height: 4px;
  position: absolute;
  width: 100%;
  z-index: 0;
  @media (min-width: 1024px) {
    display: none;
  }
}

.links-container {
  display: flex;
  align-items: center;
  column-gap: clamp(1rem, calc(1rem + ((1vw - 0.625rem) * 3.4722)), 5rem);
  position: relative;
  @media (max-width: 1024px) {
    z-index: 2;
    column-gap: 2rem;
    margin: 0 auto;
  }
}

.game-link {
  --nav-content-color: var(--color_lvl_0);
  --nav-border-color: var(--color_lvl_3);
  --nav-selection-color: var(--color_bcg_accent_1); /* TODO: add women's color */
  text-decoration: none;
  padding: 0 0.5rem;
  color: var(--nav-content-color);
  text-transform: uppercase;
  font-size: 1.2em;
  font-weight: normal;
  font-style: oblique;
  line-height: 1;
  min-width: max-content;
  z-index: 1;
  cursor: pointer;
  position: relative;
  height: 100%;
  display: grid;
  place-content: center;

  &:visited {
    color: var(--nav-content-color);
  }

  &:first-child {
    margin-left: 10px;
  }

  @media (max-width: 1024px) {
    border-bottom: 4px solid var(--nav-border-color);
    padding-bottom: 16px;
  }

  &:hover,
  &:focus {
    color: var(--nav-selection-color);
  }

  &.active-page {
    --nav-content-color: var(--color_lvl_-5);
    --nav-border-color: var(--color_lvl_-5);
  }
  &.active-page::after {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    bottom: -4px; /* Adjust based on your row border width */
    height: 4px;
    background-color: var(--nav-border-color);
  }
}

.account-settings {
  width: 28px;
  height: 28px;
  /* transform: translateY(1rem); */
  border-radius: 50px;
  transition: background-color 0.3s;
  cursor: pointer;

  &:hover,
  &:focus {
    background-color: var(--color_lvl_3);
  }

  @media (max-width: 1024px) {
    display: none;
  }
}
