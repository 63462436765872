.container {
  --header-max: calc(100dvw - 6rem) !important;
  --content: var(--header-max);
  background: rgba(0, 58, 93, 1);
  color: #fff;
  font-size: 13px;
  height: 36px;
  padding: 0 10px;
  width: 100%;
  @media (max-width: 1024px) {
    display: none !important;
  }
}

.utility-bar-link {
  color: #fff;
  text-decoration: none;
  &:hover,
  &:focus {
    text-decoration: underline;
  }
}

.content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.ncaa-content-wrapper {
  align-items: center;
  display: flex;
  height: 36px;
  column-gap: 1.2rem;
}

.presented-by-container {
  display: flex;
  align-items: center;
}

.logo {
  margin-right: 8px;
  transform: translateY(1px);
}

.presented-by-text {
  font-family: "Helvetica";
  font-weight: small;
  font-size: 8px;
  letter-spacing: 0.1px;

  &:first-child {
    transform: translateY(3px);
    margin-right: 16px;
  }
}
